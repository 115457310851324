@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #617482;
}

@layer utilities {
  a,
  input {
    cursor: pointer;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #202020;
  }
  .profile_info:after {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-right: -10px;
    background: #FFFFFF;
    border-right: 1px solid #CAD5E0;
    border-bottom: 1px solid #CAD5E0;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(224deg);
  }
  .profile_info3:after {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    left: 19%;
    width: 20px;
    height: 20px;
    margin-right: -10px;
    background: #FFFFFF;
    border-right: 1px solid #CAD5E0;
    border-bottom: 1px solid #CAD5E0;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(224deg);
  }
  .profile_info2:after {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    left: 16%;
    width: 20px;
    height: 20px;
    margin-right: -10px;
    background: #FFFFFF;
    border-right: 1px solid #CAD5E0;
    border-bottom: 1px solid #CAD5E0;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(224deg);
  }
  .profile_info1:after {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    right: 14px;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-right: 1px solid #CAD5E0;
    border-bottom: 1px solid #CAD5E0;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(224deg);
  }
  .dotaction {
    position: relative;
    width: 5px;
    height: 5px;
    background-color: #617482;
    border-radius: 50%;
  }
  .dotaction:before,
  .dotaction:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    background-color: inherit;
    border-radius: inherit;
  }
  .dotaction:before {
    top: 10px;
  }
  .dotaction:after {
    top: 20px;
  }
  .checkbox-wrapper-22 .switch {
    display: inline-block;
    height: 19px;
    position: relative;
    width: 46px;
  }
  .checkbox-wrapper-22 .switch input {
    display: none;
  }
  .checkbox-wrapper-22 .slider {
    border: 1px solid #617482;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }
  .checkbox-wrapper-22 .slider:before {
    background-color: #617482;
    bottom: 3px;
    content: "";
    height: 11px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 12px;
  }
  .checkbox-wrapper-22 input:checked + .slider {
    border: 1px solid #F05B5B;
  }
  .checkbox-wrapper-22 input:checked + .slider:before {
    transform: translateX(26px);
    background-color: #F05B5B;
  }
  .checkbox-wrapper-22 .slider.round {
    border-radius: 34px;
  }
  .checkbox-wrapper-22 .slider.round:before {
    border-radius: 50%;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    background-image: url("./assets/images/down_arrow.svg");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    padding-right: 2rem;
    padding-left: 12px;
    margin-right: 13px;
    background-position: right;
    background-position: right 13px center;
  }
  #Navbar .active {
    font-weight: bold;
    color: #F05B5B;
  }
  #Navbar .active path {
    stroke: #F05B5B;
  }
  #Navbar .active g {
    stroke: #F05B5B;
  }
  #projectStatus .active {
    font-weight: 500;
    color: #F05B5B;
    text-underline-offset: 8px;
    text-decoration-line: underline;
  }
  .hideleftmenu span {
    display: none;
  }
  .showleftmenu span {
    display: inline;
    width: 185px;
  }
  #leftmenu .active {
    border-right: 4px solid #F05B5B;
    width: calc(100% + 14px);
    color: #F05B5B;
    font-weight: bold;
  }
  #leftmenu .active svg path {
    fill: #F05B5B;
  }
  .chip {
    display: inline-block;
    height: 32px;
    font-size: 11px;
    color: #617482;
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: #F9F9F9;
    margin-bottom: 5px;
    margin-right: 10px;
    border: 1px solid #E1E1E1;
    border-radius: 50px;
  }
  .chip .close {
    cursor: pointer;
    float: right;
    font-size: 16px;
    line-height: 32px;
    padding-left: 8px;
  }
  #customcheckbox input:checked {
    background-color: #F05B5B;
    border: 1px solid #F05B5B;
  }
  #customcheckbox input:checked ~ span:last-child {
    --tw-translate-x: 1.75rem;
    /* translate-x-7 */
    border: 1px solid #F05B5B;
    margin-left: -13px;
  }
  .quotafullActive svg path {
    fill: #F05B5B;
  }
  .quotafullActive svg #Ellipse_30,
  .quotafullActive svg #Ellipse_31,
  .quotafullActive svg #Rectangle_1415 {
    fill: #F05B5B;
  }
  .quotafullActive svg #Ellipse_34 {
    stroke: #F05B5B;
  }
  .dfpActive svg path {
    fill: #F05B5B;
  }
}
.paginate_button {
  border: 1px solid #CBCBCB !important;
  border-radius: 10px !important;
}

.paginate_button.current {
  background-color: #E9E9E9 !important;
}

.paginate_button i {
  color: #A1A1A1 !important;
  font-size: 15px !important;
}

.paginate_button:hover {
  background-color: #E9E9E9 !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container .cus-check {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  left: 0px;
  z-index: 1000;
  height: 15px;
  width: 15px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: white;
  border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #F05B5B;
  border: 1px solid #F05B5B;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.selected-tile {
  border-color: #F05B5B;
}

.opacity {
  filter: opacity(60%);
}